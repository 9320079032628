import React from 'react';
import './Services.css';
import image11 from '../images/image11.jpg';
import image7 from '../images/image7.jpg';
import image13 from '../images/image13.jpg';


class Services extends React.Component {
    render() {
        return (
            <div>
                <div class="card-componenet">
                    <h1 class='header'>OUR SERVICES</h1>
                    <div class="row row-cols-1 row-cols-md-3 g-4 services">
                        <div class="col">
                            <div class="card h-100">
                                <img src={image7} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">IT CONSULTING</h5>
                                    <hr />
                                    <p class="card-text">We work closely with clients to help them understand their IT needs, and implement custom IT services and solutions that solve complex business problems.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100">
                                <img src={image11} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">DEVELOPMENT</h5>
                                    <hr />
                                    <p class="card-text">Our company specializes in developing complex business intelligence software applications that help businesses automate processes and streamline workflows.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100">
                                <img src={image13} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">STAFFING</h5>
                                    <hr />
                                    <p class="card-text">We do provide Staffing which refers to the continuous process of finding, selecting, evaluating and developing a working relationship with current or future employees.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default Services;
