import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './ContactUs.css';
import image17 from '../images/image17.jpg';
import image18 from '../images/image18.jpg';

const ContactUs = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        let formDiv = document.getElementById('contact-us-form');
        let successMessage = document.getElementById('success-message');

        emailjs.sendForm('service_6q4h2s8', 'template_gl8fj2d', form.current, 'h77wjj-Ok99ttb9Uv')
            .then((result) => {
                console.log(result.text);
                formDiv.classList.add("hidden-class");
                successMessage.classList.remove('hidden-class');
            }, (error) => {
                console.log(error.text);
            });
    };

    (() => {
        'use strict'

        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        const forms = document.querySelectorAll('.needs-validation')

        // Loop over them and prevent submission
        Array.from(forms).forEach(form => {
            form.addEventListener('submit', event => {
                if (!form.checkValidity()) {
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)
        })
    })()

    return (
        <div className='contact-us-page'>
            <div>
                <img src={image18} class="d-block w-100 contact-us-image" alt="..." />
            </div>
            <h3 class="contact-us-heading">Contact Us</h3>
            <div class='row contact-us'>
                <div class='col-sm-12 col-lg-6'>
                    <form id='contact-us-form' ref={form} onSubmit={sendEmail}>
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Name</label>
                            <input type="name" class="form-control" id="exampleFormControlInput1" name="user_name" required />
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Email address</label>
                            <input type="email" class="form-control" id="exampleFormControlInput1" name="user_email" required />
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Phone Number</label>
                            <input type="tel" class="form-control" id="exampleFormControlInput1" name="user_phone" required />
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">Enquiry Message</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" name="message"></textarea>
                        </div>
                        <div class="col-auto submit-button">
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </form>
                    <div id='success-message' class="hidden-class">
                        <img src={image17} class="d-block w-100" alt="..." />
                        <br></br>
                        <br></br>
                        <h5>We will be in touch soon!</h5>
                    </div>
                </div>
                <div class='col-sm-12 col-lg-6'>
                    <br></br>
                    <div class="card location">
                        {/* <img class="card-img-top" src="..." alt="Card image cap" /> */}
                        <div class="card-body">
                            <h5 class="card-title">Our Location</h5>
                            <hr></hr>
                            <p class="card-text">Address: 3143 Streamside Dr, Davidson, NC 28036 </p>
                            <p class="card-text">Phome: +1 (980)-890-7373</p>
                            <p class="card-text">Email: info@knglobaltech.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ContactUs;





{/* <form ref={form} onSubmit={sendEmail}>
    <label>Name</label>
    <input type="text" name="user_name" />
    <label>Email</label>
    <input type="email" name="user_email" />
    <label>Message</label>
    <textarea name="message" />
    <input type="submit" value="Send" />
</form> */}
