import React from 'react';
import image16 from '../images/image16.jpg';
import './AboutUs.css'


const AboutUs = () => {
    return (
        <div>
            <img src={image16} class="d-block w-100 contact-us-image" alt="..." />
            <div class="about-us">
                <h4>About US</h4>
                <p>KN GLOBAL TECH is leading IT consulting and application development company. We works closely with clients to help them understand their IT needs, and implements custom IT services and solutions that solve complex business problems. We do provide Staffing which refers to the continuous process of finding, selecting evaluating and developing a working relationship with current or future employees. </p>
            </div>
        </div >
    )
}

export default AboutUs;