import React from 'react';

class Footer extends React.Component {

    render() {
        return (
            <div>
                <div class="container">
                    <footer class="py-3 my-4">
                        <ul class="nav justify-content-center border-bottom pb-3 mb-3">
                            <li class="nav-item"><a href="/" class="nav-link px-2 text-muted">HOME</a></li>
                            <li class="nav-item"><a href="/aboutus" class="nav-link px-2 text-muted">ABOUT US</a></li>
                            <li class="nav-item"><a href="/contact" class="nav-link px-2 text-muted">CONTACT US</a></li>
                        </ul>
                        <p class="text-center text-muted">© 2024 KN GLOBAL TECH LLC</p>
                    </footer>
                </div>
            </div>
        )
    }
}

export default Footer;
