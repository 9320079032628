import React from 'react';
import SearchBar from './components/SearchBar';
import Title from './components/Title';
import Carousel from './components/Carousel';
import ContactUs from './components/ContactUs';
import Route from "./components/Route";
import Services from './components/Services';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs'


class App extends React.Component {
  render() {
    return (
      <div>
        <Title />
        <Route path="/">
          <Carousel />
          <Services />
        </Route>
        <Route path="/aboutus">
          <AboutUs />
        </Route>
        <Route path="/contact">
          <ContactUs />
        </Route>
        <Footer />
      </div>
    );
  }
}

export default App;
